<template>
  <div
    :id="input_id"
    :ref="input_id"
    v-click-outside="clickOutside"
    class="count"
    @click="showInput"
  >
    <div
      v-show="!is_edit"
      :class="{ cursor: !fixed, error: value <= 0 || error || errored }"
      class="nowrap"
    >
      {{ value ? formatValue(value) : def_val }} {{ symbol }}
    </div>
    <b-form-input
      v-if="is_edit"
      ref="value-edit"
      v-model="new_value"
      v-mask="mask"
      :is-keyup="true"
      type="number"
      class="input"
      :class="{ error: error || errored }"
      @keyup.enter="handleInput"
      @input="input"
    />
    <span v-if="second_value">
      <span class="slash">/</span><span>{{ second_value }}</span>
    </span>
    <!-- <img
      v-if="is_edit"
      ref="enter-info"
      class="enter"
      src="/img/icons/hotkey_enter.svg"
      alt=""
    /> -->
    <tooltip-icon-enter
      v-if="is_edit"
      target="enter-info"
    />
    <b-tooltip
      v-if="error && !is_edit"
      target="tooltip-target-1"
      triggers="hover"
      placement="top"
    >
      Количество принятых марок должно совпадать с количеством принимаемого товара
    </b-tooltip>
  </div>
</template>

<script>
  import TooltipIconEnter from '@/views/operational-processes/components/TooltipIconEnter'
  import { TaxRateModel } from '@/models/tax-rate.model'

  export default {
    name: 'TableRowInput',
    components: {
      TooltipIconEnter
    },
    apollo: {
      TaxRates: {
        query: require('../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = data?.TaxRates?.map((el) => new TaxRateModel(el)) ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        skip() {
          return !this.tax_rate
        }
      }
    },
    props: {
      mask: String,
      type_input: String,
      focus: Boolean,
      input_id: String,
      fixed: {
        type: Boolean,
        default: true
      },
      value: {
        type: Number,
        default: () => 0
      },
      second_value: {
        type: Number,
        default: () => 0
      },
      symbol: {
        type: String,
        default: () => ''
      },
      error: {
        type: Boolean,
        default: () => false
      },
      def_val: {
        type: String,
        default: '0'
      },
      digits: {
        type: Number,
        default: 2
      }
    },
    data() {
      return {
        new_value: '',
        is_edit: false,
        taxRateList: [],
        errored: null,
        timer: null
      }
    },
    mounted() {
      this.$root.$on(this.input_id, () => {
        // your code goes here
        this.showInput()
      })
    },
    created() {
      this.$refs[this.input_id] = this
    },
    methods: {
      showInput() {
        if (this.fixed) return
        this.is_edit = true
        this.new_value = this.value
        this.$nextTick(() => {
          this.$refs['value-edit']?.select()
          let element = this.$refs[this.input_id]
          while (element.tagName !== 'TD' && element?.parentNode) {
            element = element.parentNode
          }
          this.$emit('on-focus', {
            offset: element.offsetLeft + element.clientWidth / 2
          })
        })
      },

      promotionMask(val) {
        if (+val <= 100 && val.length < 3) {
          this.new_value = +val
        } else if (+val > 100) {
          this.new_value = 100
        }
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('input', +this.new_value)
        }, 500)
      },

      handleInput() {
        this.$emit('handle-input', +this.new_value)
        this.is_edit = false
      },

      input(val) {
        if (this.mask) {
          if (this.type_input === 'percent_promotion') {
            this.promotionMask(val)
            return
          }
        } else {
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.$emit('input', +this.new_value)
          }, 500)
        }
      },

      clickOutside() {
        if (this.is_edit) {
          clearTimeout(this.timer)
          this.$emit('input', +this.new_value)
        }
        this.is_edit = false
      },
      formatValue(value) {
        if (!value) value = 0
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: this.digits,
          minimumFractionDigits: 0
        })

        return formatter.format(+value)
      }
    }
  }
</script>

<style scoped lang="scss">
  .nowrap {
    white-space: nowrap;
  }
  .input {
    min-width: 50px;
    width: 100%;
  }

  .count {
    position: relative;
    padding: 6px;
    background-color: white;
    transition: 0.15s ease-in;

    display: flex !important;
    align-items: center;
    justify-content: center;

    .cursor {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px dotted #313131;
    }

    &:hover {
      background-color: transparent !important;
      //transition: 300ms;
    }
  }

  ::v-deep() {
    .count {
      .cursor {
        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }

      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }
  }
  .slash {
    margin-left: 3px;
    margin-right: 3px;
  }
</style>
