<template>
  <section id="egais-wrapper">
    <egais-spec-table :filter_params="filter_params" />
  </section>
</template>
<script>
  import EgaisSpecTable from '../components/egais/EgaisSpecTable.vue'
  export default {
    components: {
      EgaisSpecTable
    },
    data() {
      return {
        filter_params: {
          fixed: false,
          operation: 'realization',
          buyer: 'ИП Ипшник',
          base_number: 343434,
          number: 18902,
          date: new Date(1970, 10, 12),
          base_date: new Date(2012, 12, 22).toLocaleDateString('ru')
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  // #egais-wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   flex: 1;
  //   // height: 100% !important;
  // }

  ::v-deep() {
    .b-table__navbar {
      margin: 0 !important;
    }
    .b-action-buttons {
      button {
        justify-content: center;
      }
    }
    table {
      tr {
        td {
          border-left: none;
          border-right: none;
        }
      }
    }

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      &__content {
        flex: 1;
      }
    }

    .table-docs {
      margin-bottom: 0;
      min-height: calc(100%);
      height: calc(100%);
      tr {
        th.b-checkbox {
          padding: 10px;
          width: 40px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
        td.b-checkbox {
          padding: 10px;
          width: 40px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;
          }
        }
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          background: #e2e2e2;
          color: #313131;

          img {
            margin-right: 0;
            transition: all 0.2s;
            transform: rotate(180deg);
          }

          &.not-collapsed {
            img {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .table-filter {
      align-items: center;
      button {
        background: #efefef;
        color: #313131;

        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .table-inner {
      max-width: 100%;
      flex: 1;
      height: calc(100% - 64px);
      position: relative;

      table {
        width: 100%;
        tr {
          td {
            padding: 10px 16px;
            vertical-align: middle;
            text-align: center;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }
    }

    .b-search {
      &-product {
        padding: 10px 10px 10px 126px;
        border-bottom: 1px solid #e1e1e1;
        border-top: 1px solid #e1e1e1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &__wrapper {
        position: relative;
        width: 321px;
      }

      &-result {
        position: absolute;
        z-index: 10;
        bottom: 45px;
        left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 460px;
        overflow-y: auto;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;

            span {
              margin-right: 12px;
            }
          }

          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .b-table__navbar {
      margin: -35px 15px 0;
    }

    .count {
      display: inline-block;
      padding: 6px;

      .cursor {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        background: #efefef;
        img {
          opacity: 1;
        }
      }
    }
  }

  #egais-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .table-docs {
      .custom-control-label {
        &:before,
        &:after {
          left: -8px;
          box-shadow: none !important;
        }
      }
    }

    .table-filter {
      button {
        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .b-search {
      &-result {
        .item {
          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .count {
      &:hover {
        background: #efefef;
      }
    }
  }
</style>
