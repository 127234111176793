var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "egais-wrapper"
    }
  }, [_c('egais-spec-table', {
    attrs: {
      "filter_params": _vm.filter_params
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }