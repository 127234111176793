var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tooltip', {
    attrs: {
      "target": _vm.target,
      "triggers": "hover",
      "delay": {
        show: 100,
        hide: 0
      },
      "boundary": "viewport",
      "placement": "topright",
      "offset": "-10"
    }
  }, [_vm._v(" Нажмите Enter что бы ввести значение ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }