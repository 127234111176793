var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickOutside,
      expression: "clickOutside"
    }],
    ref: _vm.input_id,
    staticClass: "count",
    attrs: {
      "id": _vm.input_id
    },
    on: {
      "click": _vm.showInput
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.is_edit,
      expression: "!is_edit"
    }],
    staticClass: "nowrap",
    class: {
      cursor: !_vm.fixed,
      error: _vm.value <= 0 || _vm.error || _vm.errored
    }
  }, [_vm._v(" " + _vm._s(_vm.value ? _vm.formatValue(_vm.value) : _vm.def_val) + " " + _vm._s(_vm.symbol) + " ")]), _vm.is_edit ? _c('b-form-input', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.mask,
      expression: "mask"
    }],
    ref: "value-edit",
    staticClass: "input",
    class: {
      error: _vm.error || _vm.errored
    },
    attrs: {
      "is-keyup": true,
      "type": "number"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleInput.apply(null, arguments);
      },
      "input": _vm.input
    },
    model: {
      value: _vm.new_value,
      callback: function ($$v) {
        _vm.new_value = $$v;
      },
      expression: "new_value"
    }
  }) : _vm._e(), _vm.second_value ? _c('span', [_c('span', {
    staticClass: "slash"
  }, [_vm._v("/")]), _c('span', [_vm._v(_vm._s(_vm.second_value))])]) : _vm._e(), _vm.is_edit ? _c('tooltip-icon-enter', {
    attrs: {
      "target": "enter-info"
    }
  }) : _vm._e(), _vm.error && !_vm.is_edit ? _c('b-tooltip', {
    attrs: {
      "target": "tooltip-target-1",
      "triggers": "hover",
      "placement": "top"
    }
  }, [_vm._v(" Количество принятых марок должно совпадать с количеством принимаемого товара ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }