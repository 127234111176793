<template>
  <div class="params-body">
    <div class="card mt-3 pb-0">
      <b-form class="form">
        <b-row class="w-100">
          <b-col cols="2">
            <label
              class="label"
              for="work_period"
            >
              Дата</label
            >
            <template v-if="fixed">
              {{ convertDate(form.date) }}
            </template>
            <template v-else>
              <date-picker
                id="work_period"
                v-model="form.date"
                class="elm-calendar"
                placeholder="Выберите дату"
                :clearable="false"
                :disabled="fixed"
                format="dd.MM.yyyy"
                :is-keyup="true"
              />
            </template>
          </b-col>

          <b-col cols="2">
            <label
              class="label"
              for="operation"
            >
              Операция
              <img
                v-b-tooltip.hover.right="tooltip.operation"
                class="ml-1"
                style="height: 16px; width: 16px"
                src="/img/icons/icon-info.svg"
                alt=""
            /></label>
            <template>
              <b-input
                disabled
                :value="valueOperation"
              />
            </template>
          </b-col>

          <b-col cols="2">
            <b-form-group
              label="Номер основания"
              label-for="base_number"
            >
              <template>
                <b-input
                  disabled
                  :value="filter_params.base_number"
                />
              </template>
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group
              label="Дата основания"
              label-for="base_date"
            >
              <template>
                <b-input
                  disabled
                  :value="filter_params.base_date"
                />
              </template>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col cols="4">
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    v-model="form.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'EgaisFilters',
    props: {
      menu_list: {
        type: Array,
        default: () => []
      },

      filter_params: {
        type: Object,
        default: null
      },

      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        operation_list: [
          {
            name: 'Возврат',
            tooltip: 'Система выполнит сверку остатка только по позициям в документе',
            id: 'refund'
          },
          {
            name: 'Списание',
            tooltip: 'В документ добавятся товары выбранных групп с учетным остатком',
            id: 'write-downs'
          },
          {
            name: 'Перемещение',
            tooltip: 'В документ добавятся все товары с учетным остатком',
            id: 'movement'
          },
          {
            name: 'Реализация',
            tooltip: 'В документ добавятся все товары с учетным остатком',
            id: 'realization'
          }
        ],
        price_type_list: [
          {
            name: 'Закупочная',
            id: 'purchase'
          },
          {
            name: 'Розничная',
            id: 'retail'
          }
        ],
        tooltip: {
          operation: 'Операция из документа основания'
        },
        users_list: [{ name: 'Ч', id: 1 }],
        storages_list: [{ id: 1, name: 'Стора' }],
        form: {
          date: new Date(),
          storage: {
            id: '',
            name: ''
          },
          user: {
            id: '',
            name: ''
          },
          comment: '',
          price_type: 'purchase',
          type: 'document',
          operation: 'refund'
        },
        isUpdatedFilterParams: false
      }
    },
    computed: {
      valueOperation() {
        return this.operation_list.find((obj) => {
          if (obj.id === this.filter_params.operation) {
            return obj
          }
        }).name
      },
      valueBuyer() {
        return this.filter_params.buyer ? this.filter_params.buyer : 'Получатель'
      }
    },
    methods: {
      changeStorage(id) {
        this.storages_list.filter((obj) => {
          if (obj.id === id) {
            this.form.storage = obj
          }
        })
      },
      changeUser(id) {
        this.users_list.filter((obj) => {
          if (obj.id === id) {
            this.form.user = obj
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .btn-extra-param {
      border-bottom: 1px solid #313131;
      display: inline-block;

      .isShow {
        display: none;
      }
    }
    textarea {
      overflow: hidden;
      resize: none;
    }

    .col-2 {
      padding-top: 16px;
    }
  }
  .params-body {
    .form {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 15px 18px;

      ::v-deep .form-group {
        min-width: 150px;
        margin-bottom: 0;
      }
    }

    .btn-extra-param {
      &.not-collapsed {
        .isHide {
          display: none;
        }

        .isShow {
          display: inline;
        }
      }
    }
  }
  .label {
    height: 16.54px;
    margin-bottom: 6px !important;
    display: block;
    font-size: 12px !important;
    align-items: center !important;
  }
  .card {
    padding-top: 0 !important;
  }
</style>
