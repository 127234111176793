<template>
  <b-tooltip
    :target="target"
    triggers="hover"
    :delay="{ show: 100, hide: 0 }"
    boundary="viewport"
    placement="topright"
    offset="-10"
  >
    Нажмите Enter что бы ввести значение
  </b-tooltip>
</template>

<script>
  export default {
    props: {
      target: {
        type: String,
        default: ''
      }
    }
  }
</script>
