<template>
  <div class="b-table__wrapper">
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <b-button
          v-b-toggle.filters-col
          class="btn-filters cursor"
        >
          Параметры документа
          <img
            class="ml-3"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="b-action-buttons">
        <b-button
          v-if="!filter_params?.fixed"
          variant="outline-primary"
          class="cursor"
          @click="back"
        >
          Сохранить как черновик
        </b-button>
        <b-dropdown
          no-caret
          variant="none"
          class="ml-3"
          style="height: 32px; width: 32px; margin-right: 10px"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/Dots.svg"
                alt="Dots"
              />
            </div>
          </template>
          <b-dropdown-item>Отменить ТТН</b-dropdown-item>
          <b-dropdown-item>Удалить</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-collapse
      id="filters-col"
      visible
    >
      <egais-filters
        ref="egais-spec-filters"
        :fixed="filter_params.fixed"
        :filter_params="filter_params"
      />
    </b-collapse>
    <div class="b-table__content">
      <div class="table-filter">
        <b-form-input
          v-model="filter"
          type="text"
          class="filter-search"
          placeholder="Поиск"
          :is-keyup="true"
        />
        <b-dropdown
          no-caret
          variant="none"
          class="ml-3"
          style="height: 32px; width: 32px"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/settings.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item v-b-modal.sort-table-modal> Изменить столбцы </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="table-inner">
        <resizable-table
          table_name="egais_spec_table"
          :default_fields="fields"
          :items="product_list"
          :busy="false"
          empty_text="Ничего не найдено"
        >
          <template #head_id>
            <e-checkbox
              position="center"
              :checked="allSelected"
              @click="selectAll()"
            />
          </template>

          <template #body_id="data">
            <div class="position-relative">
              <e-checkbox
                position="center"
                :checked="isSelectedItem(data.item.id)"
                @click="selectRow(data.item.id)"
              />
            </div>
          </template>
          <template #body_number="{ item }">
            {{ item.number }}
          </template>
          <template #body_name_product="{ item }">
            {{ item.product?.FullName }}
          </template>
          <template #body_volume="{ item }">
            {{ item.product?.AlcVolume }}
          </template>
          <template #body_strong="{ item }">
            {{ item.product?.Capacity }}
          </template>
          <template #body_measurement="{ item }">
            {{ item.measurement }}
          </template>
          <template #body_count="{ item }">
            <div style="display: flex; justify-content: space-between; align-items: center">
              <table-row-input
                :value="item.quantity"
                :fixed="false"
                @handle-input="(val) => setInput(val, item)"
              />
            </div>
          </template>
          <template #body_price="{ item }">
            {{ item.product?.product?.retailPrice }}
          </template>
          <template #body_view="{ item }">
            <div>
              {{ item.product?.ProductVCode }}
              <img
                v-b-tooltip.hover.right="tooltip.view"
                class="ml-1"
                style="height: 16px; width: 16px"
                src="/img/icons/icon-info.svg"
                alt=""
              />
            </div>
          </template>
        </resizable-table>
      </div>

      <table-navbar
        :items="selected"
        items_name="egais-spec"
        @remove_items="removeProducts"
        @show_move="showMove"
      />
    </div>
  </div>
</template>
<script>
  import TableRowInput from '@/views/operational-processes/components/TableRowInput.vue'
  import EgaisFilters from './EgaisFilters.vue'
  import TableNavbar from '../../../shared/components/table/TableNavbar.vue'
  import ResizableTable from '@/components/ResizableTable'
  import { mapActions } from 'vuex'
  export default {
    name: 'EgaisSpecTable',
    components: {
      ResizableTable,
      TableNavbar,
      EgaisFilters,
      TableRowInput
    },
    props: {
      filter_params: Object
    },
    apollo: {
      WriteOffSpecs: {
        query: require('../../gql/writeOffSpec.graphql'),
        variables() {
          return {
            input: {
              act: this.$route.params.id
            }
          }
        },
        result({ data }) {
          this.product_list = data.WriteOffSpecs.specs.map((el, index) => {
            return {
              number: index + 1,
              measurement: el.product?.product?.measurement?.name,
              ...el
            }
          })
        }
      }
    },
    data() {
      return {
        filter: '',
        selected: [],
        is_request_update: false,
        product_list: [],

        storages_list: [{ id: 1, name: 'Стора' }],
        tooltip: {
          view: 'Напитки алкогольные медовые за исключением медовухи'
        },
        table: {
          take: 20,
          skip: 0,
          total: 0,
          diff_only: false
        },
        tableSheet: {
          total: 0,
          skip: 0,
          take: 20
        },
        allSelected: false,
        fields: [
          {
            key: 'id',
            label: '',
            width: 40,
            class: 'center',
            checked: true
          },
          {
            key: 'number',
            label: '№',
            width: 150,
            checked: true,
            class: 'center',
            sortable: true
          },
          {
            key: 'name_product',
            label: 'Наименование товара',
            width: 150,
            checked: true
          },
          {
            key: 'volume',
            label: 'Объём, Л',
            width: 200,
            checked: true
          },
          {
            key: 'strong',
            label: 'Крепость, %',
            width: 200,
            checked: true
          },
          {
            key: 'measurement',
            label: 'Ед.изм',
            width: 200,
            checked: true
          },
          {
            key: 'count',
            label: 'Количество',
            width: 200,
            checked: true
          },
          {
            key: 'price',
            label: 'Цена, ₽',
            width: 200,
            checked: true
          },
          {
            key: 'view',
            label: 'Вид',
            width: 200,
            checked: true
          }
        ]
      }
    },
    watch: {
      selected() {
        if (!this.selected.length && this.allSelected) this.allSelected = false
      }
    },
    beforeMount() {
      this.setBreadcrumbs({ title: this.page_title, is_go_back: true })
    },

    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    methods: {
      ...mapActions({ setBreadcrumbs: 'breadcrumbs/set_current' }),
      isSelectedItem(id) {
        return this.selected.includes(id)
      },
      selectAll() {
        this.allSelected = !this.allSelected
        if (this.allSelected) {
          this.selected = this.product_list.map((obj) => obj.id)
        } else {
          this.selected = []
        }
      },

      selectRow(id) {
        if (this.selected.includes(id)) {
          this.selected = this.selected.filter((obj) => obj !== id)
        } else {
          this.selected.push(id)
        }
      },
      removeProducts() {
        this.tableIsBusy = true
        // this.$apollo
        //   .mutate({
        //     mutation: require('../../pages/inventory/gql/RemoveInventorySpec.graphql'),
        //     variables: {
        //       document_head: this.currentDocument.id,
        //       ids: items
        //     }
        //   })
        //   .then(({ data }) => {
        //     this.product_list = []
        //     const statusOnServer = data.RemoveInventSpecification.status

        //     if (statusOnServer) {
        //       this.updateProductTable()
        //     } else {
        //       this.$noty.show(`Ошибка при удалении товара`)
        //       this.tableIsBusy = false
        //     }
        //   })
        //   .catch((e) => {
        //     console.error('error: ', e)
        //     this.tableIsBusy = false
        //   })

        this.selected = []
        this.select_all = false
      },
      showMove() {
        this.$bvModal.show('product-movement')
      },
      back() {},
      setInput(val, item) {
        this.product_list = this.product_list.map((obj) => {
          if (item.id === obj.id) {
            obj.count = val
          }
          return obj
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .btn-light {
    background-color: #e2e2e2;
    margin-left: 16px;
  }
  ::v-deep .not-collapsed {
    img {
      transform: rotate(180deg);
    }
  }
  .b-table__content {
    flex: 1 !important;
    display: flex;
    flex-direction: column;
  }
  ::v-deep .dropdown-menu {
    left: -15px !important;
  }
</style>
