var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "params-body"
  }, [_c('div', {
    staticClass: "card mt-3 pb-0"
  }, [_c('b-form', {
    staticClass: "form"
  }, [_c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('label', {
    staticClass: "label",
    attrs: {
      "for": "work_period"
    }
  }, [_vm._v(" Дата")]), _vm.fixed ? [_vm._v(" " + _vm._s(_vm.convertDate(_vm.form.date)) + " ")] : [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "work_period",
      "placeholder": "Выберите дату",
      "clearable": false,
      "disabled": _vm.fixed,
      "format": "dd.MM.yyyy",
      "is-keyup": true
    },
    model: {
      value: _vm.form.date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })]], 2), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('label', {
    staticClass: "label",
    attrs: {
      "for": "operation"
    }
  }, [_vm._v(" Операция "), _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.right",
      value: _vm.tooltip.operation,
      expression: "tooltip.operation",
      modifiers: {
        "hover": true,
        "right": true
      }
    }],
    staticClass: "ml-1",
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": "/img/icons/icon-info.svg",
      "alt": ""
    }
  })]), [_c('b-input', {
    attrs: {
      "disabled": "",
      "value": _vm.valueOperation
    }
  })]], 2), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Номер основания",
      "label-for": "base_number"
    }
  }, [[_c('b-input', {
    attrs: {
      "disabled": "",
      "value": _vm.filter_params.base_number
    }
  })]], 2)], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата основания",
      "label-for": "base_date"
    }
  }, [[_c('b-input', {
    attrs: {
      "disabled": "",
      "value": _vm.filter_params.base_date
    }
  })]], 2)], 1)], 1), _c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-extraparams",
      modifiers: {
        "collapse-extraparams": true
      }
    }],
    staticClass: "btn-extra-param cursor mb-3"
  }, [_c('span', {
    staticClass: "isHide"
  }, [_vm._v("Показать")]), _c('span', {
    staticClass: "isShow"
  }, [_vm._v("Скрыть")]), _vm._v(" дополнительные параметры ")]), _c('b-collapse', {
    staticClass: "row mb-3",
    attrs: {
      "id": "collapse-extraparams"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Комментарий",
      "label-for": "comment"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "comment",
      "rows": "3",
      "max-rows": "6",
      "is-keyup": true
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }