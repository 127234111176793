var render = function render(){
  var _vm$filter_params;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('div', {
    staticClass: "b-table__filter d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "b-toggle-button"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.filters-col",
      modifiers: {
        "filters-col": true
      }
    }],
    staticClass: "btn-filters cursor"
  }, [_vm._v(" Параметры документа "), _c('img', {
    staticClass: "ml-3",
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "b-action-buttons"
  }, [!((_vm$filter_params = _vm.filter_params) !== null && _vm$filter_params !== void 0 && _vm$filter_params.fixed) ? _c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Сохранить как черновик ")]) : _vm._e(), _c('b-dropdown', {
    staticClass: "ml-3",
    staticStyle: {
      "height": "32px",
      "width": "32px",
      "margin-right": "10px"
    },
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/Dots.svg",
            "alt": "Dots"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("Отменить ТТН")]), _c('b-dropdown-item', [_vm._v("Удалить")])], 1)], 1)]), _c('b-collapse', {
    attrs: {
      "id": "filters-col",
      "visible": ""
    }
  }, [_c('egais-filters', {
    ref: "egais-spec-filters",
    attrs: {
      "fixed": _vm.filter_params.fixed,
      "filter_params": _vm.filter_params
    }
  })], 1), _c('div', {
    staticClass: "b-table__content"
  }, [_c('div', {
    staticClass: "table-filter"
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск",
      "is-keyup": true
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-dropdown', {
    staticClass: "ml-3",
    staticStyle: {
      "height": "32px",
      "width": "32px"
    },
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.sort-table-modal",
      modifiers: {
        "sort-table-modal": true
      }
    }]
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1), _c('div', {
    staticClass: "table-inner"
  }, [_c('resizable-table', {
    attrs: {
      "table_name": "egais_spec_table",
      "default_fields": _vm.fields,
      "items": _vm.product_list,
      "busy": false,
      "empty_text": "Ничего не найдено"
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "position": "center",
            "checked": _vm.allSelected
          },
          on: {
            "click": function ($event) {
              return _vm.selectAll();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticClass: "position-relative"
        }, [_c('e-checkbox', {
          attrs: {
            "position": "center",
            "checked": _vm.isSelectedItem(data.item.id)
          },
          on: {
            "click": function ($event) {
              return _vm.selectRow(data.item.id);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_number",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.number) + " ")];
      }
    }, {
      key: "body_name_product",
      fn: function (_ref2) {
        var _item$product;

        var item = _ref2.item;
        return [_vm._v(" " + _vm._s((_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.FullName) + " ")];
      }
    }, {
      key: "body_volume",
      fn: function (_ref3) {
        var _item$product2;

        var item = _ref3.item;
        return [_vm._v(" " + _vm._s((_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : _item$product2.AlcVolume) + " ")];
      }
    }, {
      key: "body_strong",
      fn: function (_ref4) {
        var _item$product3;

        var item = _ref4.item;
        return [_vm._v(" " + _vm._s((_item$product3 = item.product) === null || _item$product3 === void 0 ? void 0 : _item$product3.Capacity) + " ")];
      }
    }, {
      key: "body_measurement",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.measurement) + " ")];
      }
    }, {
      key: "body_count",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "justify-content": "space-between",
            "align-items": "center"
          }
        }, [_c('table-row-input', {
          attrs: {
            "value": item.quantity,
            "fixed": false
          },
          on: {
            "handle-input": function (val) {
              return _vm.setInput(val, item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_price",
      fn: function (_ref7) {
        var _item$product4, _item$product4$produc;

        var item = _ref7.item;
        return [_vm._v(" " + _vm._s((_item$product4 = item.product) === null || _item$product4 === void 0 ? void 0 : (_item$product4$produc = _item$product4.product) === null || _item$product4$produc === void 0 ? void 0 : _item$product4$produc.retailPrice) + " ")];
      }
    }, {
      key: "body_view",
      fn: function (_ref8) {
        var _item$product5;

        var item = _ref8.item;
        return [_c('div', [_vm._v(" " + _vm._s((_item$product5 = item.product) === null || _item$product5 === void 0 ? void 0 : _item$product5.ProductVCode) + " "), _c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: _vm.tooltip.view,
            expression: "tooltip.view",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "ml-1",
          staticStyle: {
            "height": "16px",
            "width": "16px"
          },
          attrs: {
            "src": "/img/icons/icon-info.svg",
            "alt": ""
          }
        })])];
      }
    }])
  })], 1), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": "egais-spec"
    },
    on: {
      "remove_items": _vm.removeProducts,
      "show_move": _vm.showMove
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }